<template>
  <form>
    <md-card>
      <md-card-header
        class="md-card-header-icon"
        :class="getClass(headerColor)"
      >
        <div class="card-icon">
          <md-icon>perm_identity</md-icon>
        </div>
        <h4 class="title">
          {{ $t(`plans.${oper}`) }}
          <small></small>
        </h4>
      </md-card-header>
      <md-card-content>
        <div class="md-layout md-size-50">
          <div class="md-layout-item md-small-size-100 md-size-50">
            <div class="md-layout-item md-small-size-100 md-size-50">
              <md-field
                :class="[
                  {'md-valid': !errors.has('name') && touched.name},
                  {'md-error': errors.has('name')}
                ]"
              >
                <label>{{ $t('plans.name') }}</label>
                <md-input
                  v-model="name"
                  type="name"
                  data-vv-name="name"
                  required
                  v-validate="modelValidations.name"
                ></md-input>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('name')">
                    close
                  </md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon
                    class="success"
                    v-show="!errors.has('name') && touched.name"
                  >
                    done
                  </md-icon>
                </slide-y-down-transition>
              </md-field>
              <md-switch
                v-if="oper === 'upd'"
                :value="locked"
                class="md-primary"
                @change="onLockChange"
              >
                {{ $t(`plans.${locked ? 'locked' : 'enabled'}`) }}
              </md-switch>
            </div>
          </div>
          <div class="md-layout-item md-size-100 text-right">
            <md-button
              class="md-success"
              native-type="submit"
              @click.native.prevent="validate"
            >
              {{ $t('buttons.save') }}
            </md-button>
            <md-button class="md-accent" @click.stop.prevent="onCancel">
              {{ $t('buttons.close') }}
            </md-button>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </form>
</template>
<script>
import {mapState} from 'vuex'
import {SlideYDownTransition} from 'vue2-transitions'
import Swal from 'sweetalert2'

export default {
  name: 'edit-profile-form',
  props: {
    headerColor: {
      type: String,
      default: ''
    },
    oper: String
  },
  components: {
    SlideYDownTransition
  },
  created() {
    const {pid = null} = this.$route.params
    this.pid = pid
    if (this.oper === 'upd' && pid) {
      this.$store.dispatch('LOAD_PLAN_BY_ID', pid).then((res) => {
        const plan = Array.isArray(res) ? res[0] : res
        this.name = plan.name
        this.locked = Boolean(plan.locked_at)
      })
    }
  },
  data() {
    return {
      pid: null,
      name: null,
      locked: false,
      touched: {
        name: false
      },
      modelValidations: {
        name: {
          required: true,
          min: 3
        }
      }
    }
  },
  methods: {
    async onLockChange() {
      try {
        this.locked = await this.$store.dispatch(
          'PLAN_CHANGE_LOCK_STATUS',
          this.pid
        )
      } catch (err) {}
    },
    onCancel() {
      this.$router.push('/plans')
    },
    getClass: function(headerColor) {
      return 'md-card-header-' + headerColor + ''
    },
    async validate() {
      this.$validator.validateAll().then((isValid) => {
        if (isValid) {
          const plan = {
            name: this.name
          }

          const alert = {
            type: 'success',
            text: this.$t(`plans.plan_was_${this.oper}ed`, {
              name: this.name
            }),
            footer: ''
          }

          const reqData = this.oper === 'add' ? plan : {id: this.pid, plan}
          this.$store.dispatch(`${this.oper.toUpperCase()}_PLAN`, reqData).then(
            () => {
              Swal.fire(alert).then(() => {
                this.touched.name = false
                this.$nextTick(() => this.$validator.reset())
              })
            },
            (err) => {
              alert.type = 'error'
              alert.text = this.$t(`plans.plan_was_not_${this.oper}ed`, {
                name: this.name
              })
              alert.footer = err
              Swal.fire(alert)
            }
          )
        }
      })
    }
  },
  watch: {
    name() {
      this.touched.name = true
    }
  },
  computed: {
    ...mapState({
      //groups: (state) => state.groups.groups
    })
  }
}
</script>
<style>
.md-button + .md-button {
  margin-left: 10px;
}
</style>
